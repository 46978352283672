<template>
  <div>
    <!-- SECTION: MODAL IMPORT -->
    <employees-import-modal
      v-if="showImportModal"
      :show-import-modal.sync="showImportModal"
      @fetch-data="refetchData"
    />

    <!-- SECTION: Filters -->
    <EmloyeesListFilter
      :type-filter.sync="typeFilter"
      :created-by-filter.sync="createdByFilter"
      :gender-filter.sync="genderFilter"
      :status-filter.sync="statusFilter"
      :employee-code.sync="employeeCode"
      :type-of-employee-options-filter="typeOfEmployeeOptionsFilter"
      :gender-options="genderOptions"
      :status-options="statusOptions"
      :empty-filter="isEmptyFilter"
      @fetch-data="refetchData"
      @reset="clearFilter"
    />

    <!-- SECTION: Table Container Card -->
    <b-card
      no-body
      class="mb-0"
      style="max-height: 70vh"
    >
      <!-- Table Top -->
      <div class="mx-2 my-1">
        <b-row
          align-v="center"
          class="justify-content-md-between flex-md-nowrap"
        >
          <b-col
            cols="4"
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sizePerPageLgOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
              <span class="text-muted ml-50">{{ $t('employee.employees') }}</span>
            </v-select>
          </b-col>

          <b-col
            cols="8"
            md="auto"
            class="d-flex justify-content-end px-50"
            order-md="3"
          >
            <div class="d-none d-sm-flex">
              <!-- Button create -->
              <b-button
                :disabled="!canAccess('employee.createEmployee')"
                class="mr-1 px-lg-1 px-sm-75"
                variant="info"
                @click="() => $router.push({name: 'apps-employees-add'})"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="PlusSquareIcon"
                    size="16"
                  />
                  <span class="d-none d-sm-inline ml-50">{{ $t('employee.btnCreate') }}</span>
                </span>
              </b-button>

              <!-- button DELETE -->
              <!-- <b-button
                  variant="danger"
                  class="mx-1"
                  :disabled="activeDelete()"
                  @click="confirmDelete()"
                >
                  <span class="text-nowrap">
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      style="cursor: pointer"
                    />
                    {{ $t('delete') }}
                  </span>
                </b-button> -->

              <!-- Import -->
              <b-button
                variant="info"
                :disabled="!canAccess('employee.importEmployee')"
                class="mr-1 px-lg-1 px-sm-75"
                @click="showImportModalHandle"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="UploadIcon"
                    size="16"
                  />
                  <span class="d-none d-sm-inline ml-50">{{ $t('import') }}</span>
                </span>
              </b-button>

              <!-- Export -->
              <b-button
                class="px-lg-1 px-sm-75"
                variant="info"
                :disabled="!canAccess('employee.exportEmployee')"
                @click="confirmExport()"
              >
                <span class="text-nowrap">
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="cursor-pointer"
                  />
                  <span class="d-none d-sm-inline ml-50">{{ $t('export') }}</span>
                </span>
              </b-button>
            </div>
            <div class="d-block d-sm-none">
              <b-dropdown
                variant="info"
                class="m-md-2"
                boundary="window"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MenuIcon"
                    size="16"
                  />
                  <span class="pl-50">{{ $t('employee.moreDropdown.title') }}</span>
                </template>
                <b-dropdown-item
                  :disabled="!canAccess('employee.createEmployee')"
                  @click="() => $router.push({name: 'apps-employees-add'})"
                >
                  {{ $t('employee.moreDropdown.add') }}
                </b-dropdown-item>
                <!-- FIXME <b-dropdown-item
                  :disabled="!canAccess('employee.importEmployee')"
                  @click="showImportModalHandle"
                >
                  {{ $t('employee.moreDropdown.import') }}
                </b-dropdown-item> -->
                <b-dropdown-item
                  :disabled="!canAccess('employee.exportEmployee')"
                  @click="confirmExport()"
                >
                  {{ $t('employee.moreDropdown.export') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>

          <b-col
            cols="12"
            md="auto"
            class="mt-1 mt-md-0 px-50 flex-grow-1"
          >
            <!-- <div class="d-flex align-items-center justify-content-end"> -->
            <!-- Search Text -->
            <b-input-group
              size="md"
              class=""
            >
              <template #prepend>
                <div class="d-flex align-items-center border rounded-left px-1 bg-light-info">
                  <feather-icon
                    icon="SearchIcon"
                    size="20"
                  />
                </div>
              </template>

              <b-form-input
                v-model="searchTextFilter"
                :disabled="!canAccess('employee.searchEmployee')"
                type="search"
                :placeholder="$t('employee.placeholderSearch')"
                :value="searchTextFilter"
                :reduce="val => val.value"
                maxlength="255"
                debounce="500"
                trim
                @input="(val) => $emit('update:searchTextFilter', val)"
              />
            </b-input-group>
            <!-- </div> -->
          </b-col>
        </b-row>
      </div>

      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <!-- Table Content -->
        <b-table
          ref="refEmployeeListTable"
          style="max-height: 46vh"
          sticky-header
          :items="fetchEmployeesByFilter"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          :sort-desc.sync="isSortDirDesc"
          :sort-by.sync="sortBy"
          no-border-collapse
        >

          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`employee.${data.label}`) }}
            </span>
          </template>

          <!-- <template #cell(checkbox)="data">
            <b-form-checkbox
              class="ml-2 mr-0 mt-50"
              name="check-box"
              inline
              :checked="isChecked(data.item.id)"
              @change="chooseItem(data.item.id)"
            />
          </template> -->

          <template #cell(employeeCode)="data">
            <div class="text-nowrap">
              <b-link
                :disabled="!canAccess('employee.detailEmployee')"
                :to="{
                  name: 'apps-employees-edit',
                  params: { id: data.item.id },
                }"
                class="font-weight-bold d-block text-nowrap text-info"
              >
                <span
                  class="align-text-top text-capitalize font-weight-bold"
                >
                  {{ data.item.employeeCode }}
                </span>
              </b-link>
            </div>
          </template>

          <template #cell(account)="data">
            <h5 class="font-weight-bolder align-text-top">
              <feather-icon
                size="14"
                icon="UserIcon"
              />
              {{ data.item.username.toUpperCase() || 'N/A' }}
            </h5>
            <div>
              <small class="align-items-center d-flex flex-nowrap">
                <feather-icon
                  size="14"
                  icon="MailIcon"
                  class="mr-25"
                />
                <span>{{ data.item.emailAddress || 'N/A' }}</span>
              </small>
            </div>
            <div>
              <small class="align-items-center d-flex flex-nowrap">
                <feather-icon
                  size="14"
                  icon="PhoneCallIcon"
                  class="mr-25"
                />
                {{ data.item.phoneNumber || 'N/A' }}
              </small>
            </div>
          </template>

          <template #cell(information)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="70"
                  :src="data.item.avatar"
                  :text="avatarText(`${data.item.lastName} ${data.item.firstName}`)"
                  :variant="`light-${resolveEmployeeTypeVariant(data.item.type)}`"
                />
              </template>
              <div class="d-flex flex-column justify-content-center align-items-start">
                <h5
                  class="mb-50 text-nowrap"
                >
                  {{ data.item.lastName }} {{ data.item.firstName }}
                </h5>
                <small class="d-flex justify-content-start align-items-center">
                  <feather-icon
                    size="14"
                    icon="AwardIcon"
                    class="mr-25"
                  />
                  <span :class="`text-${resolveEmployeeTypeVariant(data.item.type)} font-weight-bold`">
                    {{ $te(resolveEmployeeTypeTitle(data.item.type)) ? $t(resolveEmployeeTypeTitle(data.item.type)) : resolveEmployeeTypeTitle(data.item.type) }}
                  </span>
                </small>
                <small class="d-flex justify-content-start align-items-center">
                  <feather-icon
                    size="14"
                    icon="TagIcon"
                    class="mr-25"
                  />
                  <small class="">{{ $t(resolveGender(data.item.gender, 'value', 'label')) }} </small>
                </small>
              </div>
            </b-media>
          </template>

          <template #cell(isActive)="data">
            <IAmSwitch
              :checked.sync="data.item.isActive"
              name="check-button"
              :confirm="true"
              custom-class="mr-0 mt-50 custom-control-success"
              :disabled="(data.item.isActive ? !canAccess('employee.activeEmployee') : !canAccess('employee.deactiveEmployee'))
                || data.item.id === meDataEmpId
                || (getMeDataType !== 'ADM' && data.item.type === 'ADM')"
              @input="changeActive(data.item)"
            />
          </template>

          <template #cell(createdAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ dateTime(data.item.createdAt) }}
                </h6>
                <small class="text-nowrap">
                  {{
                    data.item.createdBy
                      ? `${data.item.createdBy.firstName} ${data.item.createdBy.lastName}`
                      : ''
                  }}</small>
              </b-media-body>
            </b-media>
          </template>

          <template #cell(updatedAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ dateTime(data.item.updatedAt) }}
                </h6>
                <small class="text-nowrap">
                  {{
                    data.item.updatedBy
                      ? `${data.item.updatedBy.firstName} ${data.item.updatedBy.lastName}`
                      : ''
                  }}</small>
              </b-media-body>
            </b-media>
          </template>
        </b-table>
      </b-overlay>

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Showing -->
          <b-col
            cols="12"
            sm="2"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-nowrap">{{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }}
              <span class="d-inline-block d-sm-none d-md-inline"> {{ $t('paginationText.outOf') }} </span>
              <span class="d-none d-sm-inline d-md-none"> / </span>
              {{ dataMeta.of }} {{ $t('paginationText.items') }}</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEmployees"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination, BMediaBody, BMediaAside,
  BFormInput,
  BInputGroup,
  BOverlay, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  onUnmounted, ref, computed,
} from '@vue/composition-api'

import store from '@/store'
import {
  typeOfEmployeeOptions, genderOptions, statusOptions, sizePerPageLgOptions, resolveGender, typeOfEmployeeOptionsFilter,
} from '@/constants/selectOptions'

import { avatarText, dateTime } from '@core/utils/filter'

import employeeStoreModule from '@employee/employeeStoreModule'
import useEmployeeHandle from '@employee/useEmployeeHandle'

export default {
  components: {
    BMediaBody,
    BMediaAside,
    BCard,
    BRow,
    BDropdown,
    BDropdownItem,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BFormInput,
    BInputGroup,
    BOverlay,

    vSelect,
    EmloyeesListFilter: () => import('@employee/employees-list/EmployeesListFilter.vue'),
    EmployeesImportModal: () => import('@employee/employees-list/employees-import/EmployeesImportModal.vue'),
    IAmSwitch: () => import('@/components/IAmSwitch.vue'),
  },
  setup() {
    const EMPLOYEE_APP_STORE_MODULE_NAME = 'app-employee'

    // Register module
    if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) {
      store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME)
      }
    })

    const isAll = ref(false)
    const deleteIds = ref([])

    // const createdByOptions = ref([])
    // const isLoading = ref(false)
    // const createdByOptionsDefault = ref([])

    // function handleOpenCreatedBy() {
    //   if (createdByOptionsDefault.value.length === 0) {
    //     fetchEmployeesByFilterSearch()
    //   } else {
    //     createdByOptions.value = createdByOptionsDefault.value
    //   }
    // }

    // const handleSearchCreatedBy = debounce(search => {
    //   if (search) fetchEmployeesByFilterSearch(search)
    // }, 500)

    function confirmExport() {
      // show modal confirm delete employee
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.exportEmployees(this.$i18n.locale)
            this.deleteIds = []
          }
        })
    }

    function changeActive(employee) {
      if (employee.isActive) {
        this.updateDeactive(employee.id)
      } else {
        this.updateActive(employee.id)
      }
    }

    function chooseItem(id) {
      this.deleteIds = this.setArray(this.deleteIds, id)
    }

    function setArray(array, id) {
      const index = array ? array.findIndex(element => element === id) : -1
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? array.splice(index, 1) : array.push(id)

      return array
    }

    function isChecked(id) {
      return this.deleteIds.includes(id)
    }

    function activeDelete() {
      return this.deleteIds.length === 0
    }

    const getMeDataType = computed(() => store.getters['userStore/getMeDataType'])

    const {
      fetchEmployeesByFilter,
      deleteEmployee,
      tableColumns,
      sizePerPage,
      currentPage,
      totalEmployees,
      dataMeta,
      sortBy, isSortDirDesc,
      refEmployeeListTable,
      refetchData, clearFilter,
      updateDeactive,
      updateActive,
      bulkDeleteEmployees,

      // UI
      resolveEmployeeTypeVariant,
      resolveEmployeeTypeIcon,
      resolveEmployeeTypeTitle,
      resolveEmployeeIsActiveVariant,

      // Extra Filters
      typeFilter,
      genderFilter,
      searchTextFilter,
      statusFilter,
      employeeCode,
      createdByFilter,

      exportEmployees,

      // Loading
      loading,
      isEmptyFilter,
    } = useEmployeeHandle()

    // SECTION: IMPORT MODAL
    const showImportModal = ref(false)

    const showImportModalHandle = () => {
      showImportModal.value = !showImportModal.value
    }

    const meData = computed(() => store.getters['userStore/getMeData'])
    const meDataEmpId = computed(() => meData.value.id)
    return {
      // empRole,
      isAll,
      deleteIds,
      chooseItem,
      isChecked,
      setArray,

      // Sidebar
      changeActive,
      updateDeactive,
      updateActive,
      fetchEmployeesByFilter,
      deleteEmployee,
      bulkDeleteEmployees,

      activeDelete,
      // confirmDelete,
      dateTime,
      tableColumns,
      totalEmployees,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refEmployeeListTable,
      refetchData,
      clearFilter,

      // Params
      sizePerPage, // to API: size
      currentPage, // to API: page

      // Filter
      avatarText,

      // UI
      resolveEmployeeTypeVariant,
      resolveEmployeeTypeIcon,
      resolveEmployeeTypeTitle,
      resolveEmployeeIsActiveVariant,

      // select Options
      typeOfEmployeeOptions,
      typeOfEmployeeOptionsFilter,
      genderOptions,
      resolveGender,
      statusOptions,
      sizePerPageLgOptions,

      // Extra Filters
      typeFilter,
      genderFilter,
      searchTextFilter,
      statusFilter,
      employeeCode,
      createdByFilter,

      confirmExport,
      exportEmployees,

      // Modal
      showImportModalHandle,
      showImportModal,
      // inputCsvToArray, refInputCsvEl,

      // Loading
      loading,

      // Empty filter
      isEmptyFilter,

      meDataEmpId,

      // createdByOptions,
      // handleSearchCreatedBy,
      // fetchEmployeesByFilterSearch,
      // isLoading,
      // handleOpenCreatedBy,
      getMeDataType,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  width: 100%;
  max-width: 90px;
  .vs__selected-options {
    flex-wrap: nowrap
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
// Switch color
.custom-switch .custom-control-input:not(:checked) ~ .custom-control-label:before {
    background-color: #ea5455; // same danger color of bootstap
}
</style>
